import axios from "axios";
import {API_URL} from "@/const";
import { saveAs } from "file-saver";

export default {
  data() {
    return {};
  },

  methods: {
    // CSVダウンロード押下
    downloadFileApi(id, fileName) {
      this.isDownloading = true;
      return axios
        .get(API_URL.ZIPCODE.DOWNLOAD + id, {
          responseType: "blob",
        })
        .then((res) => {
          const blob = new Blob([res.data], {
            type: res.data.type,
          });
          this.finishedDownloadCSVHandler()
          saveAs(blob, fileName);
        })
        .catch((err) => {
          this.finishedDownloadCSVHandler()
          console.log(err);
        });
    },

    finishedDownloadCSVHandler() {
      this.isDownloading = false;
    },
  },
};
