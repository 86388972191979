<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle=" 郵便番号登録CSV " />
    <AppSideMenu />
    <main class="l-main">
      <div class="l-container">
        <div class="l-contents l-box">
          <div class="l-box title-wrap">
            <h3 class="c-lead icon">郵便番号情報</h3>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>郵便番号CSVデータ</label>
            </div>
            <template v-if="!selectedCsvFile">
              <div class="c-inputwrap__items">
                <input
                  id="csv"
                  accept=".csv"
                  type="file"
                  @change="(e) => onSelectCsvFile(e)"
                />
                <label for="csv" class="c-btn secondary upload sm"
                  >CSVファイル選択</label
                >
              </div>
            </template>
            <template v-else>
              <a class="c-entrustText__text">
                {{ selectedCsvFile.name }}
              </a>
              <div
                @click="deleteSelectedCsv()"
                class="c-btn secondary delete sm"
                :class="{ disabled: csvUploadProcessing }"
              >
                削除
              </div>
            </template>
          </div>

          <div class="c-errMsg__list" v-if="this.nameLengthError">
            <p>{{"CSVファイル名は拡張子含めて"+ this.nameMaxLength + "文字以内に変更してください。"}}</p>
          </div>

          <div class="c-errMsg__list" v-if="this.uploadErrMessage">
            <p>{{ this.uploadErrMessage }}</p>
          </div>
        </div>
      </div>
      <div class="l-container l-btns">
        <div class="center">
          <div
            class="c-btn primary mid register"
            @click="isDisplayConfirmRegisterModal = true"
            :class="{ disabled: selectedCsvFile == null || nameLengthError || csvUploadProcessing }"
          >
            登録
          </div>
        </div>
      </div>
      <div class="l-container">
        <div class="l-flex cols l-item">
          <div class="u-txt-bold">CSVアップロード履歴一覧</div>
        </div>
      </div>
      <div class="l-container">
        <div class="l-box">
          <div class="c-csvList__wrap scroll">
            <table class="c-csvList">
              <tbody>
                <tr class="c-csvList__row head">
                  <th class="c-csvList__cell w50">ID</th>
                  <th class="c-csvList__cell w-50p">ファイル名</th>
                  <th class="c-csvList__cell">作成日</th>
                  <th class="c-csvList__cell">更新者</th>
                  <th class="c-csvList__cell">ダウンロード</th>
                </tr>
                <template v-for="(item, index) in uploadedCsvHistory.zipcodeFileList">
                  <tr class="c-csvList__row list" :key="index">
                    <td class="c-csvList__cell">{{ item.id }}</td>
                    <td class="c-csvList__cell">{{ item.fileName }}</td>
                    <td class="c-csvList__cell">{{ item.createDate }}</td>
                    <td class="c-csvList__cell">{{ item.updateMember.name }}</td>
                    <td class="c-csvList__cell">
                      <div
                        class="c-btn secondary download"
                        :class="{ disabled: isDownloading }"
                        @click="downloadFile(item)"
                      />
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      
    </main>

    <!-- アップロード確認のモーダル -->
    <div v-if="isDisplayConfirmRegisterModal" class="c-modal sm">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body">
          郵便番号データを登録します<br />
          よろしいですか？
        </div>
        <div class="c-modal__foot">
          <div
            class="c-btn secondary small"
            @click="isDisplayConfirmRegisterModal = false"
          >
            戻る
          </div>
          <div 
            class="c-btn primary small"
            :class="{ disabled: csvUploadProcessing }"
            @click="registerHandler">登録
          </div>
        </div>
      </div>
    </div>

    <AppFooter />
  </div>
</template>

<script>
import axios from "axios";
import csvFile from "./mixins/csvFile";
import { API_URL } from "@/const/index";

export default {
  props: {
    onClickUploadButton: {
      type: Function,
    },
  },
  mixins: [csvFile],

  data() {
    return {
      menu: this.$store.getters["menu/get"],
      user: this.$store.getters["userInfo/get"],
      isGenerator: this.$store.getters["userInfo/isGenerator"],
      isCollectProxy: this.$store.getters["userInfo/isCollectProxy"],
      isDisposer: this.$store.getters["userInfo/isDisposer"],
      selectedCsvFile: null,
      csvUploadProcessing: false,
      isDisplayConfirmRegisterModal: false,
      count: 0,
      csvError: false,
      nameLengthError: false,
      isDownloading: false,
      uploadedCsvHistory: {},
      nameMaxLength: 255,
      uploadErrMessage: null
    };
  },

  created() {
    this.getUploadHistory()
  },

  mounted() {
    window.onload = () => {
      alert("ページが読み込まれました");
    };
  },

  methods: {
    //郵便番号アップロード履歴を取得
    getUploadHistory() {
      axios
        .get(API_URL.ZIPCODE.SEARCH)
        .then((res) => {
          this.uploadedCsvHistory = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //csvアップロードボタン押下時
    onSelectCsvFile(e) {
      this.selectedCsvFile = e.target.files[0];
      if (this.selectedCsvFile.name.length > this.nameMaxLength) {
        this.nameLengthError = true;
      }
    },

    //アップロードしたファイル削除
    deleteSelectedCsv() {
      console.debug("deleteSelectedCsv");
      this.selectedCsvFile = null;
      this.nameLengthError = false;
      this.uploadErrMessage = null;
    },

    //アップロードしたファイルの登録
    registerHandler() {
      this.csvUploadProcessing = true
      this.isDisplayConfirmRegisterModal = false;
      const params = new FormData();
      params.append("file", this.selectedCsvFile);
      params.append("fileName", this.selectedCsvFile.name);
      axios.post(API_URL.ZIPCODE.UPLOAD, params, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.csvUploadProcessing = false;
          this.getUploadHistory()
        })
        .catch((err) => {
          this.csvUploadProcessing = false;
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.message
          ) {
            this.uploadErrMessage = err.response.data.message
          }
          if (
            err &&
            err.response &&
            err.response.status &&
            err.response.status === 500
          ) {
            this.uploadErrMessage = "想定外のエラーが発生しました";
          }
        });
    },

    // 戻る押下（遷移元に戻る）
    backHandler() {
      this.$router.go(-1);
    },
       
    // ファイルダウンロード押下
    downloadFile(item) {
      this.downloadFileApi(item.id, item.fileName);
    },
  },
};
</script>

<style scoped>
.l-btns {
  margin-bottom: 6rem;
  width: 100%;
}
.l-btns .center {
  left: 50%;
}
.l-btns > * + * {
  margin-left: 0;
}

.l-flex {
  gap: 20px;
}

.c-btn.delete {
  margin-left: 10px;
}

.c-btn.download {
  padding: 0.5rem;
  width: 2.4rem;
  margin: 0 auto;
}
.c-btn.download:before {
  margin-right: 0;
}
.c-csvList {
  table-layout: fixed;
  width: 100%;
}
.c-csvList__cell {
  font-weight: normal;
}
.c-csvList__row th,
td {
  text-align: center;
}

.w-50p {
  width: 50%;
}

.c-errMsg__list {
  margin-left: 0;
} 
</style>
